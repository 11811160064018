import './App.css';
import logo from './assets/images/logo-kuleuven.png';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* Add image at the top */}
        <div className = "header-box">
          <div>
            <img src = { logo } alt="KU Leuven Logo" style={{ width: '140px' }} />  
          </div>
          <div className='fontBorder' style={{ fontSize: '40px', fontWeight: 'bold' }}>
            SAA 2025 - KU LEUVEN
          </div>
          <div className='fontBorder' style={{ fontSize: '40px', fontWeight: 'bold', marginBottom: "25px" }}>
            May 26-28
          </div>
          <div className='fontBorder' style={{ fontSize: '30px', fontWeight: 'bold' }}>
            Stay informed by subscribing to our mailing list
          </div>
          <div className="fontBorder register-button-container">
            <a href="https://mailing.kuleuven.be/f-ff9b5871a55176a6" className="register-button-link">
              Subscribe Now
            </a>
          </div>
          <div style={{ color: "black", fontSize: '26px', marginTop: '25px', fontStyle: "italic", fontWeight: "600" }}>
            Website coming soon ...
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
